<template>
  <div class="position-relative">
    <loader v-if="isLoading" :overlay="true" />
    <h2 class="mb-3">
      Bänneri muutmine <small v-if="banner">- {{ banner.name }}</small>
    </h2>

    <template v-if="banner">
      <b-row>
        <b-col md="6">
          <b-card header="Bänneri info">
            <b-form @submit.prevent="saveBanner">
              <b-form-group class="mb-1" label="Nimi">
                <b-form-input
                  v-model="banner.name"
                  size="sm"
                  :state="getFieldStateFromErrors('name')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("name") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Asukoht">
                <b-form-select
                  v-model="banner.position"
                  :options="positionOptions"
                  size="sm"
                  :state="getFieldStateFromErrors('position')"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ getFieldError("position") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Url">
                <b-form-input
                  v-model="banner.url"
                  size="sm"
                  :state="getFieldStateFromErrors('url')"
                ></b-form-input>
                <b-form-invalid-feedback>
                  {{ getFieldError("url") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Keel">
                <b-form-select
                  v-model="banner.locale"
                  :options="selectLocales"
                  size="sm"
                  :state="getFieldStateFromErrors('locale')"
                ></b-form-select>
                <b-form-invalid-feedback>
                  {{ getFieldError("locale") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Alguse aeg">
                <v-date-picker
                  v-model="banner.starts_at"
                  mode="dateTime"
                  is24hr
                  :model-config="dateTimeConfig"
                  :masks="dateTimeMasks"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <input
                      class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      readonly
                      :value="inputValue"
                      @click="togglePopover({ placement: 'auto-start' })"
                    />
                  </template>
                </v-date-picker>

                <b-form-invalid-feedback>
                  {{ getFieldError("starts_at") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="mb-1" label="Lõpuaeg">
                <v-date-picker
                  v-model="banner.ends_at"
                  mode="dateTime"
                  is24hr
                  :model-config="dateTimeConfig"
                  :masks="dateTimeMasks"
                >
                  <template v-slot="{ inputValue, togglePopover }">
                    <input
                      class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                      readonly
                      :value="inputValue"
                      @click="togglePopover({ placement: 'auto-start' })"
                    />
                  </template>
                </v-date-picker>

                <b-form-invalid-feedback>
                  {{ getFieldError("ends_at") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Pilt: (1112x250)">
                <b-form-file
                  v-model="form.file"
                  accept="image/*"
                  size="sm"
                  :state="getFieldStateFromErrors('file')"
                  placeholder="Vali fail"
                  drop-placeholder="Lohista fail siia"
                ></b-form-file>
                <b-form-invalid-feedback>
                  {{ getFieldError("file") }}
                </b-form-invalid-feedback>

                <div v-if="banner.image">
                  <img :src="banner.image" class="img-fluid mt-3" alt="" />
                </div>
              </b-form-group>

              <b-form-group label="Mobiili pilt: (600x600)">
                <b-form-file
                  v-model="form.mobileFile"
                  accept="image/*"
                  size="sm"
                  :state="getFieldStateFromErrors('mobileFile')"
                  placeholder="Vali fail"
                  drop-placeholder="Lohista fail siia"
                ></b-form-file>
                <b-form-invalid-feedback>
                  {{ getFieldError("mobileFile") }}
                </b-form-invalid-feedback>

                <div v-if="banner.mobile_image">
                  <img :src="banner.mobile_image" class="img-fluid mt-3" alt="" />
                </div>
              </b-form-group>

              <div class="text-right mt-3">
                <b-button type="submit" variant="success">
                  Salvesta
                </b-button>
              </div>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import RestService from "@/lib/RestService";
import ErrorsMixin from "@/helpers/ErrorsMixin";
import { mapGetters } from "vuex";

export default {
  mixins: [ErrorsMixin],
  data() {
    return {
      isLoading: false,
      banner: null,
      form: {
        file: null,
        mobileFile: null,
      },
      positionOptions: [
        { value: "home", text: "Avaleht" },
        { value: "home-sidebar", text: "Avalehe menüü all" },
      ],
      dateTimeConfig: {
        type: "string",
        mask: "iso",
      },
      dateTimeMasks: {
        inputDateTime24hr: "DD.MM.YYYY HH:mm",
      },
    };
  },
  computed: {
    ...mapGetters({
      languages: "generic/languages",
    }),
    selectLocales() {
      return this.languages.map((locale) => {
        return {
          value: locale.locale,
          text: locale.name,
        };
      });
    },
  },
  mounted() {
    this.fetchBanner();
  },
  methods: {
    fetchBanner() {
      this.isLoading = true;
      RestService.getBanner(this.$route.params.bannerId).then((response) => {
        this.setBanner(response.data.data);
        this.clearValidationErrors();

        this.isLoading = false;
      });
    },
    async saveBanner() {
      this.isLoading = true;

      let file = null;
      let mobileFile = null;
      if (this.form.file) {
        file = await this.toBase64(this.form.file);
      }

      if (this.form.mobileFile) {
        mobileFile = await this.toBase64(this.form.mobileFile);
      }

      RestService.postSaveBanner(this.banner.id, {
        ...this.banner,
        ...{
          file: file,
          mobileFile: mobileFile,
        },
      })
        .then((response) => {
          this.setBanner(response.data.data);
          this.clearValidationErrors();

          this.isLoading = false;
        })
        .catch((error) => {
          this.handleResponseErrors(error);
          this.isLoading = false;
        });
    },
    setBanner(data) {
      this.banner = data;
      this.banner.starts_at = new Date(data.starts_at);
      this.banner.ends_at = new Date(data.ends_at);
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>
